import React, { useRef } from 'react';
import image from '../assets/contactme.jpg';
import useInView from '../hooks/useInView';
import ContactMeForm from './ContactMeForm';
import Testimonials from './Testimonials';
import FadeInEffect from './FadeInEffect';

const ContactMe = () => {
  const contactMeRef = useRef(null);
  const isInView = useInView(contactMeRef);

  return (
    <>
      <div className="hidden h-screen w-screen md:flex flex-col">
        <div
          className="w-[100%] md:h-[70%] flex items-end"
          id="contactme"
          ref={contactMeRef}
          style={{ overflow: 'hidden' }}
        >
          <img
            src={image}
            className={`w-[50%] h-[100%] object-cover transition-opacity duration-[1500ms] transform ${
              isInView ? 'opacity-100' : 'opacity-0 '
            }`}
            style={{ objectPosition: '75% 100%' }}
          />
          <ContactMeForm />
        </div>{' '}
        <FadeInEffect
          isInView={isInView}
          disableTranslate={true}
          className="w-full h-[30%] bg-brand-yellow flex space-x-12 pl-8 pr-8 pb-8"
        >
          <Testimonials />{' '}
        </FadeInEffect>
      </div>

      <div className="md:hidden flex-col h-screen">
        <div className=" h-[30%]" id="contactmeMobile">
          {' '}
          <img src={image} className="w-screen h-full" />
        </div>
        <div className="h-[50%]">
          <ContactMeForm />
        </div>
        <div className="w-full h-[20%] bg-brand-yellow flex space-x-0  md:space-x-8 pl-4 md:pl-8 pr-2 md:pr-8 pb-4 md:pb-8 overflow-y-auto">
          <Testimonials />
        </div>
      </div>
    </>
  );
};

export default ContactMe;
