import React, { useState } from 'react';
import { FaStar } from 'react-icons/fa';

const Testimonials = () => {
  const [showFullTestimonial, setShowFullTestimonial] = useState(false);

  // Testimonial text
  const testimonialText =
    'Sylvie was an outstanding guide on our Beaune tour. Her deep knowledge of the region, combined with her warm and engaging personality, made the experience super enjoyable. Highly recommend! I loved that she brought me to somewhere where I could buy wine futures (super interesting experience).';

  // Function to handle click to show the full testimonial
  const handleShowFullTestimonial = () => {
    setShowFullTestimonial(true);
  };

  // Function to close the modal
  const handleCloseModal = () => {
    setShowFullTestimonial(false);
  };

  return (
    <>
      <div className="w-1/2 md:w-1/5 h-full flex flex-col justify-center overflow-x-auto">
        <p className="text-3xl md:text-header leading-none text-white">
          CUSTOMER <br />
          REVIEWS
        </p>
        <p className="text-sm md:text-lg font-medium">what you say about me:</p>
      </div>
      <div className="w-1/2 md:w-2/5 h-full flex flex-col justify-center text-lg font-light leading-relaxed">
        <p className="flex text-white text-sm md:text-base">
          <FaStar />
          <FaStar />
          <FaStar />
          <FaStar />
          <FaStar />
        </p>
        {/* Display truncated text on mobile and full text on larger screens */}
        <p className="pt-2 text-sm md:text-base cursor-pointer" onClick={handleShowFullTestimonial}>
          {/* Show first 10 words followed by "..." on mobile */}
          <span className="block md:hidden">
            {testimonialText.split(' ').slice(0, 10).join(' ')}...
            <span className="text-xs">click to view more</span>
          </span>
          {/* Show full testimonial on larger screens */}
          <span className="hidden md:block">{testimonialText}</span>
        </p>
        <p className="font-extralight text-xs">- visited in June 2024, Ben</p>
      </div>
      <div className="hidden w-2/5 h-full md:flex flex-col justify-center text-lg font-light leading-relaxed">
        <p className="flex text-white items-center text-sm md:text-base">
          <FaStar />
          <FaStar />
          <FaStar />
          <FaStar />
          <FaStar />
        </p>
        <p className="pt-2 text-sm md:text-base">
          Our Beaune tour with Sylvie was fantastic! She knew all the hidden gems and shared
          fascinating stories about the local history and culture. Her enthusiasm and
          professionalism truly enhanced our experience. Get her if you want good wines, and then
          some things other than good wines (think cheese and jams).
        </p>
        <p className="font-extralight text-xs">- visited in June 2024, Kellyn</p>
      </div>

      {/* Modal for full testimonial */}
      {showFullTestimonial && (
        <div
          className="md:hidden fixed w-full inset-0 z-50 flex items-center justify-center "
          onClick={handleCloseModal}
        >
          <div className="bg-[#f8f2dc] p-6  rounded-lg max-w-md mx-auto">
            <h3 className="text-lg font-medium">Visited in June 2024, Ben:</h3>
            <p className="mt-2 text-sm text-justify">{testimonialText}</p>
            <button
              className="mt-4 px-2 py-1 bg-[#FDC400]  text-white rounded"
              onClick={handleCloseModal}
            >
              Close
            </button>
          </div>
        </div>
      )}
    </>
  );
};

export default Testimonials;
